import { t } from "i18next";

import { svg as LinkIcon } from "@/inline-assets/link.svg";
import { svg as SearchIcon } from "@/inline-assets/search.svg";
import { SEARCH_PLACEHOLDER } from "@/search/constants.mjs";
import * as Css from "@/search/modal-template.style.mjs";
import html from "@/util/html-template.mjs";

const resultTemplate = html`
  <li
    class="{{ class }}"
    onclick="{{onResultClick}}"
    onmousedown="{{onResultMouseDown}}"
  >
    <a href="{{ url }}" keyboard-nav>
      <div class="{{imageMode}}" result-image>
        {{#imgSrc}}
        <img src="{{ src }}" />
        {{/imgSrc}} {{#imgRaw}}
        <div class="img-raw">{{{raw}}}</div>
        {{/imgRaw}} {{#gameIcon}}
        <div class="img-status" style:background-color="{{color}}">
          {{{svg}}}
        </div>
        {{/gameIcon}}
      </div>
      <div class="separate">
        <div class="name">
          <span>{{{ name }}}</span>
          {{#tagLineRender}}
          <span class="tagline">{{ tagLine }}</span>
          {{/tagLineRender}} {{#shardRender}}
          <span class="shard type-overline">{{ shard }}</span>
          {{/shardRender}}
        </div>
        <div class="flair-container">
          {{#flair}}
          <div class="flair">
            <div class="flair-flex">
              {{#imageRaw}}
              <div>{{{raw}}}</div>
              {{/imageRaw}}
              <div>{{ text }}</div>
            </div>
          </div>
          {{/flair}}
        </div>
      </div>
    </a>
    <div class="actions">
      <button class="copy-link" onclick="{{handleCopyLink}}">
        ${LinkIcon}
      </button>
      {{#actions}}
      <button class="action" onclick="{{handler}}">{{{Icon}}}</button>
      {{/actions}}
    </div>
  </li>
`;

const skellyTemplate = html`
  <li class="{{ class }}">
    <div>
      <div flash result-image class="round"></div>
      <div class="separate">
        <span flash class="name"></span>
      </div>
    </div>
  </li>
`;

export default html`
  {{#modal}}
  <div class="${Css.ModalBackdrop()}" onclick="{{ handleClose }}"></div>
  <div class="${Css.SearchContainer()}">
    <div class="${Css.SearchControls()}">
      <label for="omni-search" class="${Css.SearchInput()}">
        ${SearchIcon}
        <input
          id="omni-search"
          type="text"
          autocomplete="off"
          value="{{ query }}"
          oninput="{{ onSearchInput }}"
          onkeydown="{{ onSearchKeyDown }}"
          placeholder=" "
          focus
          keyboard-nav
        />
        <div class="placeholder">${t(...SEARCH_PLACEHOLDER)}</div>
        {{#taglineHint}}
        <div class="tagline-hint">
          <span class="hidden">{{query}}</span>
          <span class="trailing">#</span>
        </div>
        {{/taglineHint}}
      </label>
      {{#cancelButton}}
      <button class="cancel-button type-form--button" onclick="{{onclick}}">
        {{{ label }}}
      </button>
      {{/cancelButton}}
    </div>

    <div class="result-group">
      <div class="${Css.gameFilterTiles()} result-group-title">
        {{#gamesIcons}}
        <div class="${Css.GameButtons()}" data-show="{{show}}">
          {{#buttons}}
          <button
            onclick="{{onclick}}"
            style="{{style}}"
            data-selected="{{selected}}"
          >
            {{{svg}}}
          </button>
          {{/buttons}}
        </div>
        {{/gamesIcons}}
      </div>
    </div>

    <div class="${Css.scrollHelper()}">
      <div class="results-area">
        {{#resultStats}}
        <p class="result-group-title stats">{{{raw}}}</p>
        {{/resultStats}} {{#headline}}
        <h4 class="headline">{{text}}</h4>
        {{/headline}} {{#category}}
        <div class="result-group">
          <h4 class="result-group-title shade2">{{ label }}</h4>
          <ul class="results-list">
            {{#results}} ${resultTemplate} {{/results}} {{#skelly}}
            ${skellyTemplate} {{/skelly}}
          </ul>
        </div>
        {{/category}} {{#full}}
        <a
          class="${Css.InlineResultWrapper()} full-search-cta"
          href="{{href}}"
          keyboard-nav
          data-some="{{some}}"
        >
          ${SearchIcon}
          <span class="name text-semi">{{ query }}</span>
          <div class="divider"></div>
          <span class="cta-secondary">{{ cta }}</span>
        </a>
        {{/full}}
      </div>
    </div>
  </div>
  {{/modal}}
`;
